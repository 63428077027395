import React, { useEffect } from 'react'
import classNames from 'classnames'
import { path } from 'ramda'
import {
  DatePicker
} from '../../components'
import {
  AdvancedGraph
} from './'
import { useHistory } from 'react-router-dom'
import bindAllActions from '../../common/bindAllActions'
import { getDeviceLabel, getLabel, GRAPH_TYPES } from '../../common/ambient'
import { isPlus } from '../payment'
import AdvancedGraphEdit from './AdvancedGraphEdit'

const graphTypes = GRAPH_TYPES

function CreateGraph ({ device, userActions, user }) {
  const [open, setOpen] = React.useState(false)
  const [graph, setGraph] = React.useState({})
  const graphs = path(['info', 'settings', 'graphs'], user) || []

  const openForm = (evt) => {
    setOpen(!open)
    if (open) {
      setGraph({})
    }
    evt && evt.preventDefault()
  }

  let show = (
    <>
      <h3>Choose the Type of Graph</h3>
      <div className='btns'>
        {Object.keys(graphTypes).map(key => (
          <div key={key} className='btn-wrap'>
            <a
              className='btn btn-primary'
              onClick={() => {
                setGraph({
                  type: key
                })
              }}
            >
              {graphTypes[key].name}
            </a>
            <span>{graphTypes[key].description}</span>
          </div>
        ))}
      </div>
    </>
  )

  if (graph.type) {
    show = [
      <h3 key='header'>Define the {graphTypes[graph.type].name} Graph</h3>,
      <AdvancedGraphEdit
        device={device}
        key='edit'
        graph={graph}
        setGraph={setGraph}
      />
    ]
    const showCreateGraph = graph.type === 'multiDevice'
      ? graph.devices && graph.devices.length > 1 && graph.params && graph.params.length > 0
      : graph.devices && graph.devices.length > 0 && graph.params && graph.params.length > 0

    if (showCreateGraph) {
      show.push((
        <div key='create-graph' className='text-right'>
          <a
            className='btn btn-primary'
            onClick={() => {
              graph.title = graph.type === 'multiDevice' ? `${getLabel(graph.params[0].param, device)} for ${graph.devices.length} stations` : `${graph.params.length} parameters for ${getDeviceLabel(device.deviceCache[graph.devices[0].deviceId])}`
              graph._id = Date.now()
              userActions.updateSetting('graphs', [graph, ...graphs])
              setGraph({})
              openForm()
            }}
          >Create Graph</a>
        </div>
      ))
    }
  }

  if (!open) {
    show = null
  }

  return (
    <div className={classNames('device-create-device block', { open })}>
      <a onClick={openForm} className='connect'>Add an Advanced Graph</a>
      <a onClick={evt => { openForm(evt) }} className='close' />
      {show}
    </div>
  )
}

function AdvancedGraphing ({ device, userActions, user, deviceActions }) {
  const graphs = path(['info', 'settings', 'graphs'], user) || []
  const [dates, _setDates] = React.useState(device.advancedGraphingDates || [])
  const [scriptsLoaded, setScriptsLoaded] = React.useState(false);
  const history = useHistory()

  const setDates = (dates) => {
    _setDates(dates)
    deviceActions.setThing('advancedGraphingDates', dates)
  }

  useEffect(() => {
    const scriptsToLoad = [
      'https://code.highcharts.com/highcharts.js',
      'https://code.highcharts.com/modules/exporting.js',
      'https://code.highcharts.com/modules/offline-exporting.js',
      'https://code.highcharts.com/modules/export-data.js'
    ];

    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const loadScriptsSequentially = async () => {
      for (const src of scriptsToLoad) {
        await loadScript(src);
      }
      setScriptsLoaded(true);
    };

    loadScriptsSequentially();
  }, []);

  useEffect(() => {
    if (user.userChecked && !isPlus(user)) {
      history.push('/dashboard')
    }
  }, [user, history])

  if (!scriptsLoaded) {
    return null; // Return null until all scripts are loaded
  }

  let graphsShow = null
  if (graphs.length > 0) {
    graphsShow = (
      <div className='graphs'>
        <div className='graph-header'>
          <i className='glyphicon glyphicon-calendar' />
          <DatePicker
            onChange={setDates}
            start={dates[0]}
            end={dates[1]}
          />
        </div>
        {graphs.map((graph, i) => (
          <AdvancedGraph
            dates={dates}
            i={i}
            key={graph._id || i}
          />
        ))}
      </div>
    )
  }
  return (
    <div className='device-advanced-graphing'>
      <header className='main'>
        <h1>Advanced Graphing</h1>
      </header>
      <div className='page-body'>
        <CreateGraph device={device} userActions={userActions} user={user} />
        {graphsShow}
      </div>
    </div>
  )
}

export default bindAllActions(AdvancedGraphing)

AdvancedGraphing.propTypes = {}
AdvancedGraphing.defaultProps = {}

AdvancedGraphing.displayName = 'AdvancedGraphing'
