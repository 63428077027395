import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { toPairs } from 'ramda'
import { pathsChanged, getDateTz } from '../common/ambient'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import bindUserActions from '../features/user/redux/bindUserActions'
import { isPlus } from '../features/payment'

const _valueOf = d => d && d.valueOf()

class DatePickerAmb extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    user: PropTypes.object,
    onChange: PropTypes.func,
    start: PropTypes.object,
    end: PropTypes.object
  }

  state = {
    open: false,
    mode: '24',
    showRange: true
  }

  constructor (props) {
    super(props)
    this.modes = {
      24: {
        label: 'Last 24 hours'
      },
      day7: {
        label: 'Past 7 Days'
      },
      day30: {
        label: 'Past 30 Days'
      },
      day90: {
        label: 'Past 90 days'
      },
      day365: {
        label: 'Past Year'
      },
      year: {
        label: 'Year to Date'
      },
      day730: {
        label: 'Past 2 Years',
        plusOnly: true
      },
      day1095: {
        label: 'Past 3 Years',
        plusOnly: true
      },
      custom: {
        label: 'Custom'
      }
    }
  }

  componentDidMount () {
    const { onChange, device, start, end, getDate } = this.props
    this._mounted = true
    document.addEventListener('mousedown', this.handleClick, false)
    const gd = getDate || getDateTz(moment, device)
    const st8 = {
      momentTz: moment,
      getDate: gd
    }
    let skipOnChange = false
    if (start && end) {
      st8.date = start
      st8.date2 = end
      skipOnChange = true
    }
    this.setState(st8, () => {
      if (onChange && !skipOnChange) {
        onChange(this._getDates())
      }
    })
  }

  componentWillUnmount () {
    this._mounted = false
    document.addEventListener('mousedown', this.handleClick, false)
  }

  _getDates () {
    const { showRange, getDate, mode, date2 } = this.state
    if (!showRange) {
      return [date2.clone().startOf('day'), date2.clone().endOf('day')]
    } else if (mode === '24') {
      return [getDate(Date.now()).subtract(24, 'hours'), getDate(Date.now())]
    } else if (/day/.test(mode)) {
      const d = mode.match(/\d+/)[0]
      return [getDate(Date.now()).startOf('day').subtract(parseInt(d, 10), 'day'), getDate(Date.now())]
    } else if (mode === 'week') {
      return [getDate(Date.now()).startOf('week'), getDate(Date.now())]
    } else if (mode === 'month') {
      return [getDate(Date.now()).startOf('month'), getDate(Date.now())]
    } else if (mode === 'year') {
      return [getDate(Date.now()).startOf('year'), getDate(Date.now())]
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { showRange, getDate, mode, date, date2, open } = this.state
    const { onChange, start, end } = this.props

    // start and end passed in
    if (start && end && !date) {
      this.setState({
        date: start,
        date2: end,
        getDate: this.props.getDate || getDateTz(moment, this.props.device)
      })

    // first load or mode change or showRange
    } else if ((prevState.getDate && getDate && !date) ||
      (prevState.mode !== mode && mode !== 'custom') ||
      (prevState.showRange !== showRange)
    ) {
      const dates = this._getDates()
      return this.setState({
        date: dates[0],
        date2: dates[1]
      })
    }
    if (_valueOf(prevState.date) !== _valueOf(date) || _valueOf(prevState.date2) !== _valueOf(date2)) {
      if (onChange) {
        // onChange([date, date2])
      }
    }
    if (pathsChanged(prevState, this.state, ['open']) && open) {
      this.setState({
        originalDates: [date, date2]
      })
    }
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) return

    if (this._mounted) {
      this.setState({ open: false })
    }
  }

  _picker () {
    const { showRange, mode, momentTz, open, date, date2, getDate, originalDates } = this.state
    const { user, actions, onChange } = this.props
    if (!open) return
    let select

    const show = [
      <div key={1} className='picker-wrap'>
        <label>From:</label>
        <DatePicker
          inline
          selected={new Date(momentTz(date.format('YYYY-MM-DD') + ' 00:00:00').valueOf())}
          onChange={d => {
            const dirtyDate = momentTz(d)
            const newDate = getDate(dirtyDate.format('YYYY-MM-DD') + ' 00:00:00')
            if (showRange) {
              if (newDate.valueOf() > date2.valueOf()) return
              this.setState({
                date: newDate,
                mode: 'custom'
              })
            } else {
              this.setState({
                date: newDate.clone().startOf('day'),
                date2: newDate.clone().endOf('day')
              })
            }
          }}
        />
      </div>
    ]
    if (showRange) {
      show.push(
        <div key={2} className='picker-wrap pw2'>
          <label>To:</label>
          <DatePicker
            inline
            selected={new Date(momentTz(date2.format('YYYY-MM-DD') + ' 00:00:00').valueOf())}
            onChange={d => {
              const dirtyDate = momentTz(d)
              const newDate2 = getDate(dirtyDate.format('YYYY-MM-DD') + ' 00:00:00')
              if (newDate2.valueOf() < date.valueOf()) return
              this.setState({
                date2: newDate2,
                mode: 'custom'
              })
            }}
          />
        </div>
      )
      const modePairs = toPairs(this.modes)
      show.push(
        <div key={3} className='chooser'>
          {modePairs.map(arr => (
            <a
              key={'a' + arr[0]}
              onClick={() => {
                if (arr[1].plusOnly && !isPlus(user)) {
                  actions.doModal({
                    type: 'plus-subscription',
                    data: {
                      message: 'Get 3 years of data access with AWN+'
                    }
                  })
                  return
                }
                this.setState({ mode: arr[0] })
              }}
              className={classNames({ active: arr[0] === mode, plus: arr[1].plusOnly })}
            >
              {arr[1].label}
            </a>
          ))}
        </div>
      )
      select = (
        <div className='select-wrap'>
          <span>Choose Range:</span>
          <select onChange={evt => this.setState({ mode: evt.target.value })} value={mode}>
            {modePairs.map(arr => <option value={arr[0]} key={arr[0]}>{arr[1].label}</option>)}
          </select>
        </div>
      )
    }

    const btnDisabled = !originalDates || (_valueOf(date) === _valueOf(originalDates[0]) && _valueOf(date2) === _valueOf(originalDates[1]))
    return (
      <div className='picker'>
        <header>
          <a
            onClick={() => {
              if (showRange) {
                this.setState({
                  showRange: false
                })
              }
            }}
            className={classNames('single', {
              active: !showRange
            })}>Specific Date</a>
          <a
            onClick={() => {
              this.setState({
                showRange: true
              })
            }}
            className={classNames('multi', {
              active: showRange
            })}>Date Range</a>
          {select}
        </header>
        <div>{show}</div>
        <div className='btn-wrap'>
          <a
            className='btn btn-cancel'
            onClick={() => {
              this.setState({
                date: originalDates[0],
                date2: originalDates[1],
                open: false
              })
            }}
          >Cancel</a>
          <a
            className='btn btn-primary'
            disabled={btnDisabled}
            onClick={() => {
              if (btnDisabled) return
              if (onChange) {
                onChange([date, date2])
              }
              this.setState({ open: false })
            }}
          >
            Apply
          </a>
        </div>
      </div>
    )
  }

  render () {
    const { showRange, momentTz, date, date2, open } = this.state
    if (!momentTz || !date) return <div />
    const fmt = 'MMMM D, YYYY'

    return (
      <div ref={node => this.node = node} className={classNames('component-date-picker', { open, multi: showRange })}>
        <a onClick={() => this.setState({ open: !open })}>
          {date.format(fmt)}
          {showRange ? ' - ' + date2.format(fmt) : null}
        </a>
        {this._picker()}
      </div>
    )
  }
}
export default bindUserActions(DatePickerAmb)

DatePicker.displayName = 'DatePicker'
